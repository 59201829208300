<template>
  <v-card :loading="loading">
    <v-toolbar flat>
      <v-app-bar-nav-icon
        ><v-btn title="voltar" icon @click="$router.go(-1)"><v-icon>mdi-keyboard-backspace</v-icon></v-btn></v-app-bar-nav-icon
      >
      <v-card-title>{{ title }}</v-card-title>
    </v-toolbar>

    <div class="text-center pt-5 pb-5" v-if="loading">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>

    <div v-else>
      <v-stepper vertical v-model="stepIndex">
        <v-stepper-step :complete="stepIndex > 1" step="1"> Informações gerais </v-stepper-step>

        <v-stepper-content step="1">
          <AnnouncementForm
            :mod="mod"
            :adData="adData"
            :adPhones="adPhones"
            :adFacilities="adFacilities"
            :completeRegistration="true"
            :dialog="true"
            @saved="nextAction"
          />
        </v-stepper-content>

        <v-stepper-step v-if="openingHoursIsAllowed" :complete="stepIndex > 2" step="2"> Horários de funcionamento </v-stepper-step>

        <v-stepper-content v-if="openingHoursIsAllowed" step="2">
          <OpeningHours :completeRegistration="true" :adOperatingHours="adOperatingHours" @saved="nextAction" />
        </v-stepper-content>

        <v-stepper-step v-if="paymentMethodsIsAllowed" step="3"> Formas de pagamento </v-stepper-step>

        <v-stepper-content v-if="paymentMethodsIsAllowed" step="3">
          <PaymentMethods :completeRegistration="true" :adPaymentMethods="adPaymentMethods" @saved="nextAction" />
        </v-stepper-content>
      </v-stepper>
    </div>
  </v-card>
</template>

<script>
import AnnouncementForm from "@/components/Adverts/AnnouncementForm";
import OpeningHours from "@/components/Adverts/OpeningHours";
import PaymentMethods from "@/components/Adverts/PaymentMethods";
import errorHandler from "@/helpers/error_handler";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    AnnouncementForm,
    OpeningHours,
    PaymentMethods,
  },
  computed: {
    ...mapGetters("adverts", ["validateSpecificFeature"]),
    adId() {
      return this.$route.params.id ? this.$route.params.id : null;
    },
    openingHoursIsAllowed() {
      return this.validateSpecificFeature(14);
    },
    paymentMethodsIsAllowed() {
      return this.validateSpecificFeature(15);
    },
  },
  props: {
    mod: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: "Cadastro de anuncio",
    },
  },
  data() {
    return {
      stepIndex: 1,
      loading: false,
      adData: {},
      adPhones: [],
      adFacilities: [],
      adOperatingHours: [],
      adPaymentMethods: [],
      stepsData: [
        { step: 1, featureId: 0, name: "announcementForm" },
        { step: 2, featureId: 14, name: "openingHours" },
        { step: 3, featureId: 15, name: "paymentMethods" },
      ],
    };
  },
  methods: {
    ...mapActions("adverts", ["setPlanFeatures"]),
    async loadAdData() {
      try {
        this.loading = true;

        const params = { includePlanFeatures: 1 };

        const res = await this.$axios.get(`/posts/${this.adId}`, { params });

        this.formatAdData(res.data);
      } catch (error) {
        this.loading = false;
        this.handleErrors(error);
      }
    },
    formatAdData(data) {
      let copyOfData = { ...data };

      this.adPhones = copyOfData.AdPhones;
      delete copyOfData.CompanyPhones;

      this.adFacilities = copyOfData.Facilities.map((f) => {
        return f.id;
      });
      delete copyOfData.Facilities;

      this.adOperatingHours = copyOfData.OperatingHours;
      delete copyOfData.OperatingHours;

      this.adPaymentMethods = copyOfData.PaymentMethods;
      delete copyOfData.PaymentMethods;

      this.setPlanFeatures(copyOfData.Plan.Features);

      this.adData = copyOfData;

      this.loading = false;
    },
    nextAction(actionStepNumber) {
      let includedInThePlan = false;

      //verifica o indice da ação anterior no array stepsData
      let actionIndex = this.stepsData.findIndex((a) => a.step === actionStepNumber);

      if (actionIndex === -1) {
        //erro
        this.handleErrors(["Erro inesperado"]);
        return;
      }

      for (let currentStepIndex = actionIndex + 1; currentStepIndex < this.stepsData.length; currentStepIndex++) {
        //verifica se a feature Id está inclusa no plano
        includedInThePlan = this.validateSpecificFeature(this.stepsData[currentStepIndex].featureId);

        //se tiver, incrementa o stepIndex para mudar para o próximo passo e sai da função
        if (includedInThePlan) {
          this.stepIndex++;
          return;
        } else currentStepIndex++;
      }

      this.$router.push(`/anuncios`);
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      this.$root.$errorDialog(errorHandled);
    },
  },
  created() {
    if (this.mod === "update") this.loadAdData();
  },
};
</script>

<style></style>
